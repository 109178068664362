<section id="header-discover" class="responsive-bg d-flex justify-content-start">
<!-- style.background-image="url('{{post?.headerImg?.url}}')"-->
    <div class="header-title text-white d-flex justify-content-start border-danger">
        {{'KONTAKT' }}
    </div> 
</section>
    
<section id="contact">
    <div class="container">
        <div class="row fw-500">
            <div class="col-xl-8 col-lg-12">
                <div class="col-xl-12 col-lg-12 px-lg-2 d-flex justify-content-between flex-sm mx-2 mx-xxl-0 mx-xl-0 mx-lg-0 mx-md-0 mx-sm-0">
                    <div class="col-xl-4 d-flex align-items-center">
                        <div>
                             <img class="me-2 md-height-loc" src="assets/icons/location.png" alt="">
                        </div>
                       <div class="pe-5 pe-xxl-5 pe-xl-5 pe-lg-5 pe-md-2 fs-contact">
                        Kulina bana T-8, 72250 vitez BiH
                       </div>
                    </div>
                    <div class="col-xl-4 d-flex align-items-center my-sm-5 my-5 my-xxl-0 my-xl-0 my-lg-0 my-md-0">
                        <div>
                            <img class="me-2 md-height-mail" src="assets/icons/mail.png" alt="">
                       </div>
                      <div class="pe-5 pe-xxl-5 pe-xl-5 pe-lg-5 pe-md-2 fs-contact" [innerHTML]="most">
                    
                      </div>
                    </div>
                    <div class="col-xl-4 d-flex align-items-center">
                        <div>
                            <img class="me-2 md-height-phone" src="assets/icons/phone-c.png" alt="">
                       </div>
                      <div class="fs-contact">
                      +387 62 613 200<br>
                      +387 63 336 824
                      </div>
                    </div>
                </div>
                <div class="col-xl-12 mx-xxl-0 mx-xl-0 mx-lg-3 mx-md-3 mx-sm-2 mx-2">
                    <form class="form-inline row col-xxl-11 py-3 px-3 justify-content-between mt-5" role="form" id="contact-form" [formGroup]="myGroup" enctype="multipart/form-data" autocomplete="off" (ngSubmit)="submit()">
        
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-1 d-flex justify-content-center">
                          <!-- <label for="product_name" class="col-sm-12 control-label my-3">First Name</label> -->
                          <div class="mb-4 col-12">
                            <label>Ime*</label>
                            <input type="text" class="form-control form-text w-100 mt-4" id="product_name" placeholder="{{'Ime'}}" formControlName="_name">
                            <div *ngIf="groupControls?._name?.invalid &&  groupControls?._name?.touched" class="alert">
                                <div *ngIf="groupControls?._name?.errors?.required">
                                  Ime i prezime je obavezno polje.
                                </div>
                                <div *ngIf="groupControls?._name?.errors?.minlength">
                                  Ime i prezime mora imati bar pet karaktera.
                                </div>   
                              </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-1 d-flex justify-content-center">
                          <!-- <label for="product_name" class="col-sm-12 control-label my-3">Last Name</label> -->
                          <div class="mb-4 col-12">
                            <label>Prezime*</label>
                            <input type="text" class="form-control form-text w-100 mt-4" id="product_name" placeholder="{{'Prezime'}}" formControlName="_surname">
                            <div *ngIf="groupControls?._surname?.invalid &&  groupControls?._surname?.touched" class="alert">
                                <div *ngIf="groupControls?._surname?.errors?.required">
                                  Ime i prezime je obavezno polje.
                                </div>
                                <div *ngIf="groupControls?._name?.errors?.minlength">
                                  Ime i prezime mora imati bar pet karaktera.
                                </div>   
                              </div>
                          </div>
                        </div>
                        
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-1 d-flex justify-content-center">
                            <!-- <label for="product_name" class="col-sm-12 control-label my-3">Phone</label> -->
                            <div class="mb-4 col-12">
                              <label>Broj telefona*</label>
                              <input type="number" class="form-control form-text mt-4" id="product_name" placeholder="{{'+387 61 234 333'}}" formControlName="_phone">
                              <div *ngIf="groupControls?._phone?.invalid &&  groupControls?._phone?.touched" class="alert">
                                  <div *ngIf="groupControls?._phone?.errors?.required">
                                      Telefon je obavezno polje.
                                  </div>
                                  <div *ngIf="groupControls?._phone?.errors?.minlength">
                                      Telefon mora imati bar devet karaktera.
                                  </div> 
                                  <div *ngIf="groupControls?._phone?.errors?.pattern">
                                     Format mora bit : 061111111. Maksimalno 10 brojeva.
                                  </div> 
                                  <div *ngIf="groupControls?._phone?.errors?.maxLength">
                                      Telefon može imati najviše 10 karaktera.
                                  </div>  
                                </div>
                            </div> 
                          </div>

                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-1 d-flex justify-content-center">
                          <!-- <label for="product_name" class="col-sm-12 control-label my-3">Email</label> -->
                          <div class="mb-4 col-12">
                            <label>Email*</label>
                            <input type="email" class="form-control form-text mt-4" id="product_name" placeholder="{{'Email'}}" formControlName="_email"> 
                            <div *ngIf="groupControls?._email?.invalid &&  groupControls?._email?.touched" class="alert">
                                <div *ngIf="groupControls?._email?.errors?.required">
                                  Email je obavezno polje.
                                </div>
                                <div *ngIf="groupControls?._email?.errors?.pattern">
                                    Neispravan format.
                                  </div>
                                <div *ngIf="groupControls?._email?.errors?.minlength">
                                   Email mora imati bar 10 karaktera.
                                </div>   
                              </div>
                          </div>
                        </div>
                    
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-1 d-flex justify-content-center">
                          <div class="mb-4 col-12">
                            <label for="">Poruka</label>
                              <textarea class="form-control form-text mt-4" id="exampleFormControlTextarea1" rows="1" placeholder="{{'Vaša poruka za nas'}}" formControlName="_message"></textarea>
                              <div *ngIf="groupControls?._message?.invalid &&  groupControls?._message?.touched" class="alert">
                                <div *ngIf="groupControls?._message?.errors?.required">
                                    Poruka je obavezno polje.
                                </div>
                                <div *ngIf="groupControls?._message?.errors?.minlength">
                                    Poruka mora imati bar pet karaktera.
                                </div>   
                              </div>
                            </div> 
                        </div>
                        <div class="d-flex justify-content-center align-items-center">
                            <button type="submit" class="btn send" id="sendMessage">{{'POŠALJI'}}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-xl-4 col-lg-12 mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                <iframe class="first-map" style="width: 100%;  height: 100%; display: block;" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d91709.20965672478!2d17.4333195726623!3d44.085154030557604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sba!4v1666183236613!5m2!1sen!2sba"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                   
            </div>
        </div>
    </div>
</section>
<section id="header-discover" class="responsive-bg d-flex justify-content-start">
    <!-- style.background-image="url('{{post?.headerImg?.url}}')"-->
        <div class="header-title text-white d-flex justify-content-start border-danger">
          {{'O NAMA' }}
        </div> 
    </section>
<section id="aboutus">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                Udruženje gradjana za podršku osobama u duševnoj nevolji „Most“-Vitez je osnovano u oktobru 2015 .godine. Kao pravni subjekt je registrirano u martu 2016. godine kod nadleznog Ministarstva Pravde u Travniku – Srednjo-bosanski Kanton. Udruženje je samostalno,neprofitabilno i volontersko udruženje osoba sa duševnim/mentalnim poremećajima te onih koji im žele dati podršku.
                Misija Udruženja je poboljšati medjusobnu podršku, evaluirati rad bitnih medicinskih i socijalnih organizacija te pobrinuti se za povećanje kvalitete života osoba koje su označene kao „duševni bolesnici“. Udruženje se u svom radu oslanja na psihosocijalni model duševne nevolje i krize te zastupa samopomoć i pomoć usmjerenu na zajednicu.
                Članovi Udruženja su korisnici usluga mentalnog zdravlja sa područja Viteza,Busovače i Novog Travnika,članovi njihovih porodica ,profesionalci iz oblasti mentalnog zdravlja i simpatizeri Udruženja.
                Udruženje djeluje na lokalnom,kantonalnom,federalnom i državnom nivou.
                Udruženje ,između ostalog svoje aktivnosti provodi ,od 2016 g., u sklopu Projekta mentalnog zdravlja BiH,koji  podržava vlada Švajcarske a implementira IPD-Institut za populaciju I razvoj sa uredima u Sarajevu I Banjaluci u partnerstvu sa Ministarstvom zdravlja I socijalne zaštite Republike Srpske I Federalnim ministarstvom zdravstva., I igra vrlo značajnu ulogu u aktivnostima koje se sprovode u sklopu projekta mentalnog zdravlja BiH.
                Takodje udruženje aktivno učestvuje u sklopu Projekata EMPOWER i SOCIETIES 2 koje implementira Caritas BiH a finansira Evropska Unija.
                Udruženje je takođe članica Mreže Organizacija iz oblasti mentalnog zdravlja na nivou BIH I članica Mreže Organizacija Osoba sa invaliditetom SBK / KSB.
                Na lokalnom nivou Udruženje odlično saradjuje sa Centrom za mentalno zdravlje Vitez,Općinom Vitez,školama,MUP-om,J.U. Dom zdravlja Vitez,privrednim subjektima , nevladinim organizacijama I Udruženjima.
            </div>
        </div>
    </div>
</section>

<!-- <section id="goals" class="position-relative">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="title fw-bold mb-5">
                    Osnovni ciljevi udruge
                </div>
                <div class=" container-fluid">
                    <img class="position-absolute img-position" src="assets/images/line.png" alt="" >
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center my-3 me-5"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/1.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3 me-5"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/2.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content mx-auto">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3 me-5"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/3.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3 me-5"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/4.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/5.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3 me-5"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/6.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3 me-5"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/7.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3 me-5"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/8.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3 me-5"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/9.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/10.png" alt="..." class="nums">
                        <div class="mx-auto">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            </div>
            
        </div>
    </div>
</section> -->


<section id="goals" class="position-relative">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="title fw-bold mb-5">
                    Osnovni ciljevi udruge
                </div>
                <div class=" container-fluid">
                    <img class="position-absolute img-position" src="assets/images/line.png" alt="" >
                </div>
            </div>
        </div>
            <div class="row mt-3 d-flex justify-content-between" *ngIf="screenWidth>=1400">
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/1.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/2.png" alt="..." class="nums">
                            <div class="mx-auto  card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content mx-auto">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/3.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/4.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/5.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-between" *ngIf="screenWidth>=1400">
                <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/6.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/7.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/8.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/9.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/10.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
            </div>




            <div class="row mt-3 d-flex justify-content-between" *ngIf="screenWidth<1400">
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center my-3"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/1.png" alt="..." class="nums">
                        <div class="mx-auto card-title">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/2.png" alt="..." class="nums">
                        <div class="mx-auto card-title">Lorem ipsum</div>
                    </div>
                    <div class="card-content mx-auto">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/3.png" alt="..." class="nums">
                        <div class="mx-auto card-title">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/4.png" alt="..." class="nums">
                        <div class="mx-auto card-title">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                <div class="card-design py-3">
                    <div class="card-head">
                        <img src="assets/icons/5.png" alt="..." class="nums">
                        <div class="mx-auto card-title">Lorem ipsum</div>
                    </div>
                    <div class="card-content">
                        Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                    </div>
                </div>
            </div>
            
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/6.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/7.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/8.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/9.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
                <div class="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-3"> 
                    <div class="card-design py-3">
                        <div class="card-head">
                            <img src="assets/icons/10.png" alt="..." class="nums">
                            <div class="mx-auto card-title">Lorem ipsum</div>
                        </div>
                        <div class="card-content">
                            Lorem ipsum dolore sit amet, constetur sadispascing elitr, sed diam noumy
                        </div>
                    </div>
                </div>
            </div>
            

    </div>
</section>


<section id="team" class="position-relative">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 d-flex flex-column mb-5 justify-content-center align-items-center t-center-mobile">
                <div>
                    <div class="title fw-bold">
                    Članovi naše udruge
                </div>
                <div class="">
                    <img class="img-position" src="assets/images/line.png" alt="" >
                </div>
                </div>
                
            </div>
           <!-- <div class="col-xl-6 position-relative">
               
           </div> -->
        </div>

        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mobile-arrows">
            <a class="mb-3 mx-3 margin-between-mobile" role="button" data-slide="prev" onClick="$('#carouselExampleIndicators2').carousel('prev')">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="mb-3 mx-3 margin-between-mobile" role="button" data-slide="next" onClick="$('#carouselExampleIndicators2').carousel('next')">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
        </div>
        <div class="row">
            <div class="col-xxl-12 d-flex justify-content-center">
                <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row d-flex justify-content-center">
                                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-md-2 mb-sm-3 mb-mobile">
                                    <div class="card card-summer" style="border:none;">
                                        <div class="thumbnail-wrapper">
                                            <img class="border-blue" src="assets/images/1.jpg" alt="Card image cap">
                                        </div>
                                        
                                        <div class="card-summer-body d-flex flex-column text-center">
                                            <div class="d-flex justify-content-between pt-3">
                                                <div class="card-title text-center mx-auto">John Doe</div>
                                            </div>
                                            
                                            <div class="text-blue">Predsjednik udruge</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 flex-end mb-md-2 mb-sm-3 mb-mobile">
                                    <div class="card card-summer" style="border:none;">
                                        <div class="thumbnail-wrapper">
                                            <img class="border-blue" src="assets/images/2.jpg" alt="Card image cap">
                                        </div>
                                        
                                        <div class="card-summer-body d-flex flex-column text-center">
                                            <div class="d-flex justify-content-between pt-3">
                                                <div class="card-title text-center mx-auto">John Doe</div>
                                            </div>
                                            
                                            <div class="text-blue">Član udruge</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-md-2 mb-sm-3 mb-mobile">
                                    <div class="card card-summer" style="border:none;">
                                        <div class="thumbnail-wrapper">
                                            <img class="border-blue" src="assets/images/3.jpg" alt="Card image cap">
                                        </div>
                                        
                                        <div class="card-summer-body d-flex flex-column text-center">
                                            <div class="d-flex justify-content-between pt-3">
                                                <div class="card-title text-center mx-auto">John Doe</div>
                                            </div>
                                            
                                            <div class="text-blue">Član udruge</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 flex-end mb-md-2 mb-sm-3 mb-mobile">
                                    <div class="card card-summer" style="border:none;">
                                        <div class="thumbnail-wrapper">
                                            <img class="border-blue" src="assets/images/4.jpg" alt="Card image cap">
                                        </div>
                                        
                                        <div class="card-summer-body d-flex flex-column text-center">
                                            <div class="d-flex justify-content-between pt-3">
                                                <div class="card-title text-center mx-auto">John Doe</div>
                                            </div>
                                            
                                            <div class="text-blue">Član udruge</div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row d-flex justify-content-center">
    
                                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-md-2 mb-sm-3 mb-mobile">
                                    <div class="card card-summer" style="border:none;">
                                        <div class="thumbnail-wrapper">
                                            <img class="border-blue" src="assets/images/udruga.jpg" alt="Card image cap">
                                        </div>
                                        
                                        <div class="card-summer-body d-flex flex-column text-center">
                                            <div class="d-flex justify-content-between pt-3">
                                                <div class="card-title text-center mx-auto">John Doe</div>
                                            </div>
                                            
                                            <div class="text-blue">Član udruge</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 flex-end mb-md-2 mb-sm-3 mb-mobile">
                                    <div class="card card-summer" style="border:none;">
                                        <div class="thumbnail-wrapper">
                                            <img class="border-blue" src="assets/images/udruga.jpg" alt="Card image cap">
                                        </div>
                                        
                                        <div class="card-summer-body d-flex flex-column text-center">
                                            <div class="d-flex justify-content-between pt-3">
                                                <div class="card-title text-center mx-auto">John Doe</div>
                                            </div>
                                            
                                            <div class="text-blue">Član udruge</div>
                                        </div>
                                        
                                    </div>
                                </div><div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-md-2 mb-sm-3 mb-mobile">
                                    <div class="card card-summer" style="border:none;">
                                        <div class="thumbnail-wrapper">
                                            <img class="border-blue" src="assets/images/udruga.jpg" alt="Card image cap">
                                        </div>
                                        
                                        <div class="card-summer-body d-flex flex-column text-center">
                                            <div class="d-flex justify-content-between pt-3">
                                                <div class="card-title text-center mx-auto">John Doe</div>
                                            </div>
                                            
                                            <div class="text-blue">Član udruge</div>
                                        </div>
                                        
                                    </div>
                                </div><div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 flex-end mb-md-2 mb-sm-3 mb-mobile">
                                    <div class="card card-summer" style="border:none;">
                                        <div class="thumbnail-wrapper">
                                            <img class="border-blue" src="assets/images/udruga.jpg" alt="Card image cap">
                                        </div>
                                        
                                        <div class="card-summer-body d-flex flex-column text-center">
                                            <div class="d-flex justify-content-between pt-3">
                                                <div class="card-title text-center mx-auto">John Doe</div>
                                            </div>
                                            
                                            <div class="text-blue">Član udruge</div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev desktop-arrows"  role="button" data-slide="prev"  onClick="$('#carouselExampleIndicators2').carousel('prev')">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a class="carousel-control-next desktop-arrows"  role="button" data-slide="next" onClick="$('#carouselExampleIndicators2').carousel('next')">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                      </a>
                </div>
            </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 tablet-arrows mx-auto mt-2">
            <a class="mb-3 mx-3 margin-between-mobile" role="button" data-slide="prev" onClick="$('#carouselExampleIndicators2').carousel('prev')">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="mb-3 mx-3 margin-between-mobile" role="button" data-slide="next" onClick="$('#carouselExampleIndicators2').carousel('next')">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</section>
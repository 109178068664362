
<nav class="navbar navbar-expand-lg navbar-light pb-0 fw-400" (scroll)="onWindowScroll();" id="navbar">

    <!-- <div class="little-green-thing">
      <svg  xmlns="http://www.w3.org/2000/svg" width="15" height="45" viewBox="0 0 18 45"> 
        <path id="Rectangle_750" data-name="Rectangle 750" d="M0,0H18a0,0,0,0,1,0,0V45a0,0,0,0,1,0,0h0A18,18,0,0,1,0,27V0A0,0,0,0,1,0,0Z" fill="#00ad8a"/>
      </svg>
    </div> -->

    <div class="container-fluid padding-left-5rem">
      <a class="navbar-brand" routerLink="/">
        <img src="assets/images/logo.png" alt="logo" id="logo">
      </a>
        <a class="navbar-brand-mobile" routerLink="/">
          <img src="assets/images/logo.png" alt="logo" id="logo2">
        </a>
        <button class="navbar-toggler" (click)="openNav()" type="button" data-bs-toggle="collapse" data-bs-target="#eurodom-toggle" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
      
      <div class="collapse navbar-collapse row" id="eurodom-toggle">
        <!-- <div class="languages">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            </button>
            <ul class="dropdown-menu m-0 p-0" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item pointer text-center border-bottom m-0" >HRV</a></li>
              <li><a class="dropdown-item pointer text-center border-bottom m-0" >ENG</a></li>
            </ul>
          </div>
        </div> -->
        <div style="height:3rem;" class="d-flex justify-content-between align-items-center bg-dark text-white sides-header fs-header" >
            <div class="">POTREBNA VAM JE NAŠA POMOĆ?POZOVITE NAS!</div>
            <div class="nav-help-numb"> <img src="assets/icons/phone.png" height="20"> +387 62 613 200</div>
            <div class="nav-help-numb"> <img src="assets/icons/phone.png" height="20"> +387 63 336 824</div>
        </div>

        <ul class="nav navbar-nav fw-600 nav-text-size padding-right-5rem" style="height:4rem;">
            <li class="nav-item home">
                <a class="nav-link pointer"  routerLink="/pocetna"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'Početna'}}</a>
            </li>
            <li class="nav-item about">
                <a class="nav-link pointer" routerLink="/o-nama"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{'O nama' }}</a>
            </li>
            <li class="nav-item products1">
                <a class="nav-link pointer" routerLink="/novosti"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{'Novosti' }}</a>
            </li>
            <li class="nav-item about">
                <a class="nav-link pointer" routerLink="/mentalno-zdravlje"    routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{'Mentalno zdravlje' }}</a>
            </li>
            <li class="nav-item blog">
                <a class="nav-link pointer" routerLink="/projekti"   routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">{{'Projekti' }}</a>
            </li>
            <li class="nav-item blog">
                <a class="nav-link pointer" routerLink="/vasa-prava"   routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">{{'Vaša prava' }}</a>
            </li>
            <li class="nav-item blog">
                <a class="nav-link pointer" routerLink="/dokumenti"   routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">{{'Dokumenti' }}</a>
            </li>
            <li class="nav-item contact">
                <a class="nav-link pointer" routerLink="/kontakt"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{'Kontakt' }}</a>
            </li>
            <!-- <li class="nav-item dropdown lang-sm">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                </button>
                <ul class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item text-center border-bottom m-0" >HRV</a></li>
                <li><a class="dropdown-item text-center border-bottom m-0" >ENG</a></li>
                </ul>
            </li>-->
            </ul>
    
            <!-- Mobile navbar -->
            <ul class="nav navbar-nav mobile-nav">
            <li class="nav-item mx-auto">
                <a class="nav-link pointer" href="">{{'Naslovna'}}</a> 
            </li>
            <li class="nav-item mx-auto">
                <a class="nav-link pointer" href="/o-nama">{{'O nama' }}</a>
            </li>
            <li class="nav-item mx-auto">
                <a class="nav-link pointer" href="/novosti">{{'Novostii' }}</a>
            </li>
            <li class="nav-item mx-auto">
                <a class="nav-link pointer" href="/mentalno-zdravlje">{{'Mentalno zdravlje' }}</a>
            </li>
            <li class="nav-item mx-auto">
                <a class="nav-link pointer" href="/projekti">{{'Projekti' }}</a>
            </li>
            <li class="nav-item mx-auto">
                <a class="nav-link pointer" href="/vasa-prava">{{'Vaša prava' }}</a>
            </li>
            <li class="nav-item mx-auto">
                <a class="nav-link pointer" href="/dokumenti">{{'Dokumenti' }}</a>
            </li>
            <li class="nav-item mx-auto">
                <a class="nav-link pointer" href="/kontakt">{{'Kontakt' }}</a>
            </li>
            <li class="nav-item mx-auto">
              <a class="nav-link phone">
                {{'+38762 613 200' }} |
                {{'+38763 336 824' }}
              </a>
            </li>
            </ul>
            <!-- Mobile navbar -->

        
      </div>
    </div>
  </nav>

  <script>
       </script>
<footer class="bg-responsive font-family-poppins mt-5">
<div class="container">
   <div class="row mb-xl-5 mb-lg-4 mb-md-3 mb-sm-3">
        <div class="col-xl-4 col-lg-4 col-md-5 d-flex flex-column align-items-center">
            <div class="logo-container">
                <img src="assets/images/logo.png">
            </div>
            <div class="logo-text px-xxl-5 px-xl-3 px-lg-0 px-md-4">
                ID br. 42366409700004 - Udruženje
                građana za podršku osobama u duševnoj 
                nevolji MOST - Vitez 
            </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-7 position-footer-text position-relative">
            <div class="quote">
                <img src="assets/icons/icon-quote.png">
            </div>
            <div class="footer-big-text">
                Most prijateljstva i zajedništva!
            </div>
        </div>
   </div>
</div>
<div class="container-fluid bg-blue">
    <div class="row">
        <div class="container text-center text-white py-3">
            <div>
                © Udruga Most Vitez | 2022
            </div>
        </div>
    </div>
</div>
</footer>
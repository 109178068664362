<section id="header-discover" class="responsive-bg d-flex justify-content-start">
<!-- style.background-image="url('{{post?.headerImg?.url}}')"-->
    <div class="header-title text-white d-flex justify-content-start border-danger">
        {{'VAŠA PRAVA' }}
    </div> 
</section>

<section id="content">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div>
                    Odnos prema licima sa invaliditetom se mijenjao kroz vrijeme, ali ni do današnjih dana, u većini društava, odnos prema licima sa invaliditetom nije isti kao prema licima bez invaliditeta. Većina modernih društava je trenutno u fazi socijalnog uključivanja lica sa invaliditetom, zasnovanog na principima ravnopravnosti i zabrane diskriminacije. 

Tome je u značajnoj mjeri doprinio pokret lica sa invaliditetom, koji se nekoliko decenija unazad bori za ravnopravnost i poštovanje prava lica sa invaliditetom. Od početka 80-ih godina na agendi Ujedinjenih nacija bilo je nekoliko aktivnosti koje su se odnosile na ljudska prava lica sa invaliditetom, njihovo puno učešće u društvu i ravnopravnost, što je rezultiralo usvajanjem Konvencije o pravima lica sa invaliditetom (2006). Konvencija o pravima lica sa invaliditetom (2006), koju je Bosna i Hercegovina ratifikovala 2009. godine , predstavlja prekretnicu u oblasti ljudskih prava lica sa invaliditetom. 

Njen cilj je da unaprijedi, zaštiti i obezbijedi puno i ravnopravno uživanje svih ljudskih prava i osnovnih sloboda lica sa invaliditetom i da unaprijedi poštovanje njihovog urođenog dostojanstva, a zabrana diskriminacije je jedno od načela na kojima konvencija počiva. Konvencija je donijela promjenu paradigme u odnosu na lica sa invaliditetom time što se lica sa invaliditetom ne posmatraju kao neko kome treba zaštita, već kao lica koja su subjekti prava i koja samostalno odlučuju o svim pitanjima koja ih se tiču.

Većina država je dugo pristup licima sa invaliditetom zasnivala na tzv. medicinskom modelu, prema kome se invaliditet tretira kao individualna patologija koju treba liječiti i rehabilitirati. Drugim riječima, prema tom modelu se smatra da je problem u osobi koja ima invaliditet, stavlja se fokus na medicinsku dijagnozu i liječenje (“popravljanje”), a lice sa invaliditetom je pasivno i “trpi” pomoć koja mu se pruža. 

Sa druge strane, socijalni modelna kojem je Konvencija zasnovana, pomjera fokus sa dijagnoze na osobu, a država je ta koja treba da pruži jednake mogućnosti za učešće svih članova društva. S tim u vezi, težište se stavlja na barijere koje postoje u zajednici i koje otežavaju licu sa invaliditetom da iskaže svoje sposobnosti i koristi socijalne i druge resurse. 

 Promjena paradigme je uočljiva kroz definisanje pojma invaliditeta, pa je pomenutom Konvencijom propisano da lica sa invaliditetom uključuju i ona lica koja imaju dugoročna fizička, mentalna, intelektualna ili čulna oštećenja, koja u interakciji sa raznim preprekama mogu da ometaju njihovo puno učešće u društvu ravnopravno sa drugima Iz ove definicije je evidentno da se invaliditet ne posmatra kao individualni problem osobe, već da se problem vidi u društvu koje postavlja različite barijere (zakonodavne, administrativne, arhitektonske, informacione) i koje nije prilagođeno licima sa invaliditetom.

Tokom povijesti osobama s invaliditetom uskraćivan je osobni i pojedinačni izbor i kontrola u svim područjima života. Za brojne se osobe pretpostavlja da ne mogu živjeti nezavisno u zajednicama koje su same odabrale. Potpora nije dostupna ili je povezana s određenim načinom života, a infrastruktura unutar zajednice ne poštuje načelo univerzalnoga dizajna. Sredstva se ulažu u ustanove, a ne u razvoj mogućnosti kojima bi se osobama s invaliditetom omogućilo nezavisno življenje u zajednici. To je dovelo do napuštanja, ovisnosti o obitelji, institucionalizacije, izolacije i segregacije. 

U preambuli Konvencije države potpisnice navode da brojne osobe s invaliditetom žive u siromaštvu i ističu potrebu za suočavanjem s učinkom siromaštva. Trošak društvene isključenosti je visok jer se njome nastavlja ovisnost, a time i ograničavanje pojedinačnih sloboda. Društvena isključenost također stvara stigmu, segregaciju i diskriminaciju, što dovodi do nasilja, iskorištavanja i zlostavljanja, kao i negativnih stereotipa kojima se potiče ciklus marginalizacije osoba s invaliditetom. Politike i konkretni planovi djelovanja za društvenu uključenost osoba s invaliditetom, uključujući promicanjem njihova prava na nezavisno življenje (čl. 19.), predstavljaju troškovno učinkoviti mehanizam kojim se jamče uživanje prava, održivi razvoj i smanjenje siromaštva.

                </div>
            </div>
        </div>
    </div>
</section>

<section id="links" class="position-relative">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="mb-5">
                    <div class="title">
                        Referentni linkovi
                    </div>
                    <div class=" container-fluid">
                        <img class="position-absolute img-position" src="assets/images/line.png" alt="" >
                    </div>
                </div>
            </div>
            <div class="col-12" style="width: 100%;">
                <div>
Referentni linkovi

<p><strong>Usklađenost zakona u BIH sa Konvencijom o pravima osoba sa invaliditetom:</strong></p>

<ul>
    <li><a href="http://www.soih.hr/pdf/knowledge_bases/broaura_-_opi_komentari_un.pdf" rel="noopener" target="_blank">http://www.soih.hr/pdf/knowledge_bases/broaura_-_opi_komentari_un.pdf</a></li>
    <li><a href="https://fbihvlada.gov.ba/uploads/documents/konvencija-o-pravima-osoba-sa-invaliditetom_1640955166.pdf" rel="noopener" target="_blank">https://fbihvlada.gov.ba/uploads/documents/konvencija-o-pravima-osoba-sa-invaliditetom_1640955166.pdf</a></li>
</ul>
<br />
<br />
<strong>Analiza potreba, prava i položaja osoba sa invaliditetom u Bosni i Hercegovini:</strong>

<ul>
    <li><a href="https://www.sif.ba/dok/1491989715.pdf" rel="noopener" target="_blank">https://www.sif.ba/dok/1491989715.pdf</a></li>
    <li><a href="https://www.udas.rs.ba/latin/blog/analiza-potreba-prava-i-polozaj-osoba-sa-invaliditetom-u-bih" rel="noopener" target="_blank">https://www.udas.rs.ba/latin/blog/analiza-potreba-prava-i-polozaj-osoba-sa-invaliditetom-u-bih</a></li>
</ul>
<br />
<br />
<strong>Analiza sistemskih rje&scaron;enja i stavova osoba/lica sa invaliditetom u kontekstu razmatranja aktivnih i pasivnih mjera podr&scaron;ke u Federaciji Bosne i Hercegovine i Republici Srpskoj</strong>

<ul>
    <li><a href="http://www.upfbih.ba/uimages/dokumenti/Analiza20pravnog20okvira20za20zaposljavanje20osoba20sa20invaliditetom.pdf" rel="noopener" target="_blank">http://www.upfbih.ba/uimages/dokumenti/Analiza20pravnog20okvira20za20zaposljavanje20osoba20sa20invaliditetom.pdf</a></li>
    <li><a href="https://ravnopravnorazliciti.org/wp-content/uploads/2019/03/MI-SMO-TU.pdf" rel="noopener" target="_blank">https://ravnopravnorazliciti.org/wp-content/uploads/2019/03/MI-SMO-TU.pdf</a></li>
</ul>
<br />
<br />
<strong>Konvencija o pravima osoba sa invaliditetom:</strong>

<ul>
    <li><a href="http://www.mhrr.gov.ba/pdf/konvencija_bos.pdf" rel="noopener" target="_blank">http://www.mhrr.gov.ba/pdf/konvencija_bos.pdf</a></li>
    <li><a href="https://fond.ba/wp-content/uploads/2011/06/Konvencija.pdf" rel="noopener" target="_blank">https://fond.ba/wp-content/uploads/2011/06/Konvencija.pdf</a></li>
</ul>
<br />
<br />
<strong>Opća / Univerzalna deklaracija o ljudskim pravima 1948 godine:</strong>

<ul>
    <li><a href="https://www.ombudsmen.gov.ba/documents/obmudsmen_doc2013041003050667cro.pdf" rel="noopener" target="_blank">https://www.ombudsmen.gov.ba/documents/obmudsmen_doc2013041003050667cro.pdf</a></li>
    <li><a href="https://hr.wikipedia.org/wiki/Op%C4%87a_deklaracija_o_ljudskim_pravima" rel="noopener" target="_blank">https://hr.wikipedia.org/wiki/Op%C4%87a_deklaracija_o_ljudskim_pravima</a></li>
</ul>

                </div>
            </div>
        </div>
    </div>
</section>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { AboutComponent } from './pages/public/about/about.component';
import { ContactComponent } from './pages/public/contact/contact.component';
import { DocumentDetailComponent } from './pages/public/document-detail/document-detail.component';
import { DocumentsComponent } from './pages/public/documents/documents.component';
import { HomeComponent } from './pages/public/home/home.component';
import { MentalHealthComponent } from './pages/public/mental-health/mental-health.component';
import { NewsDetailComponent } from './pages/public/news-detail/news-detail.component';
import { NewsComponent } from './pages/public/news/news.component';
import { ProjectDetailsComponent } from './pages/public/project-details/project-details.component';
import { ProjectsComponent } from './pages/public/projects/projects.component';
import { YourRightsComponent } from './pages/public/your-rights/your-rights.component';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: '', redirectTo: 'pocetna', pathMatch: 'full' },
      { path: 'pocetna', component: HomeComponent },
      { path: 'o-nama', component: AboutComponent },
      { path: 'novosti', component: NewsComponent },
      { path: 'novost', component: NewsDetailComponent },
      //{ path: 'novost/:id', component:NewsDetailComponent}
      { path: 'mentalno-zdravlje', component: MentalHealthComponent },
      { path: 'dokumenti', component: DocumentsComponent },
      { path: 'projekti', component: ProjectsComponent },
      { path: 'projekt', component:ProjectDetailsComponent},
      { path: 'vasa-prava', component: YourRightsComponent },
      { path: 'dokument', component:DocumentDetailComponent},
      { path: 'kontakt', component: ContactComponent },
      // { path: 'blog', component:BlogComponent},
      // { path: 'blog-detail/:id', component:BlogDetailComponent}

      //footer pages
      // { path: 'imprint', component: ImprintComponent},
      // { path: 'jobs', component: JobsComponent},
      // { path: 'privacy-policy', component: PrivacyPolicyComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

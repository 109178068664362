<section id="header-discover" class="responsive-bg d-flex justify-content-start align-items-start">
<!-- style.background-image="url('{{post?.headerImg?.url}}')"-->
    <div class="header-title text-white d-flex  py-4">
      {{'Most prijateljstva i zajedništva!' }}
    </div> 
    <!-- <div class="container position-white-square text-center border-danger">
        <div class="white-square text-black py-5 py-sm-4">
            <div class="white-square-title">{{post?.headerTitle}}</div>
            <div class="py-4 px-4 white-square-text" [innerHtml]="(post?.headerContent)">
            </div>
            <button class="btn read-more px-5">PROČITAJ VIŠE</button>
        </div>
    </div> -->
</section>
<section id="howitbegan" class=" position-relative">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-md-3 mb-sm-3 card-mb-2">
                <div class="mb-5">
                    <div class="title">
                        Kako je sve počelo
                    </div>
                    <div class=" container-fluid">
                        <img class="position-absolute img-position" src="assets/images/line.png" alt="" >
                    </div>
                </div>
                
                 <div class="d-flex ">
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-3 col-3 year">
                      <img src="assets/images/2015.png" alt="" style="width: 100%;">
                    </div>
                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-9 col-sm-9 col-9 px-3 d-flex align-items-center">
                        Udruženje građana za podršku osobama u duševnoj nevoji "most"-Vitez je osnovano  u oktobru 2015. godine.
                    </div>
                </div>
                <div class="d-flex py-4 py-sm-3">
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-3 col-3 year">
                       <img src="assets/images/2016.png" alt="" style="width: 100%;">
                    </div>
                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-9 col-sm-9 col-9 px-3 d-flex align-items-center">
                        Kao pravni subjekt  je registrirano u martu 2016. godine kod nadleznog ministarstav pravde u Travniku - Srenjobosanski katnon.
                    </div>
                </div>
                <div>
                    Udruženje je samostalno, neprofitabilno i volontersko udruženje osoba sa duševnim, mentalnim poremećajima te onih koji im žele dati podršku.
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div class="img-holder">
                    <img src="assets/images/udruga1.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="my-5">
    <div class="container">
        <div class="row  d-flex justify-content-center align-items-center">
            <div class="col-xl-3 d-flex justify-content-between">
                <div class="card card-heart">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon my-3"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold pt-2">Lorem ipsum</div> 
                        <div class="card-text">
                            
                            <div class="">
                            Informacije o svojim članovima ili igračima uvezi u naš roster i skini ogroman teret sa svojih leđa.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="marks-1">
                        - - - -
                    </div>
            </div>
            <div class="col-xl-3 d-flex justify-content-between">
                <div class="card card-heart">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon my-3"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold">Lorem ipsum</div> 
                        <div class="card-text">
                            
                            <div class="">
                            Informacije o svojim članovima ili igračima uvezi u naš roster i skini ogroman teret sa svojih leđa.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="marks-center">
                        - - - -
                    </div>
            </div>
            <div class="col-xl-3 d-flex justify-content-between">
                <div class="card card-heart">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon my-3" style="width:100%;"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold">Lorem ipsum</div> 
                        <div class="card-text">
                            
                            <div class="">
                            Informacije o svojim članovima ili igračima uvezi u naš roster i skini ogroman teret sa svojih leđa.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="marks-center-2">
                        <div>
                            - - - -
                        </div>
                       
                    </div>
            </div>
            <div class="col-xl-3 d-flex justify-content-end">
               
                <div class="card card-heart">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon my-3"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold">Lorem ipsum</div> 
                        <div class="card-text">
                            
                            <div class="">
                            Informacije o svojim članovima ili igračima uvezi u naš roster i skini ogroman teret sa svojih leđa.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="marks-invisible">
                        <div>
                            - - - -
                        </div>
                       
                    </div>
            </div>
        </div>
    </div>
</section> -->

<section class="my-5">
    <div class="container">
        <div class="row  d-flex justify-content-center align-items-center">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center position-relative card1 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-4 mb-sm-4 card-mb-2">
                <div class="card card-heart">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon my-3"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold pt-2">Lorem ipsum</div> 
                        <div class="card-text">
                            
                            <div class="">
                            Informacije o svojim članovima ili igračima uvezi u naš roster i skini ogroman teret sa svojih leđa.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="marks-absolutw-1">
                    <div>
                        - -
                    </div>
                    
                </div>
                <div class="marks-absolutw-1-md">
                    <div>
                        - 
                    </div>
                    
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6  col-sm-6 col-12 d-flex justify-content-center position-relative card2 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-4 mb-sm-4 card-mb-2">
                <div class="marks-absolutw-2">
                    <div>
                        - -
                    </div>
                   
                </div>
                <div class="marks-absolutw-2-md">
                    <div>
                        - 
                    </div>
                   
                </div>
                <div class="card card-heart">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon my-3"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold">Lorem ipsum</div> 
                        <div class="card-text">
                            
                            <div class="">
                            Informacije o svojim članovima ili igračima uvezi u naš roster i skini ogroman teret sa svojih leđa.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="marks-absolutw-1 remove">
                        <div>
                            - -
                        </div>
                       
                    </div>
                    <div class="marks-absolutw-1-md remove-end">
                        <div>
                            - 
                        </div>
                       
                    </div>
            </div>
            <div class="col-xl-3 col-lg-3  col-md-6  col-sm-6 col-12 d-flex justify-content-center position-relative card1 card-mb-2">
                <div class="marks-absolutw-2 remove">
                    <div>
                        - -
                    </div>
                   
                </div>
                <div class="marks-absolutw-2-md remove-start">
                    <div>
                        - 
                    </div>
                   
                </div>
                <div class="card card-heart">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon my-3" style="width:100%;"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold">Lorem ipsum</div> 
                        <div class="card-text">
                            
                            <div class="">
                            Informacije o svojim članovima ili igračima uvezi u naš roster i skini ogroman teret sa svojih leđa.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="marks-absolutw-1">
                        <div>
                            - -
                        </div>
                       
                    </div>
                    <div class="marks-absolutw-1-md">
                        <div>
                            - 
                        </div>
                       
                    </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center position-relative card2">
                <div class="marks-absolutw-2">
                    <div>
                        - -
                    </div>
                   
                </div>
                <div class="marks-absolutw-2-md">
                    <div>
                        - 
                    </div>
                   
                </div>
                <div class="card card-heart">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon my-3"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold">Lorem ipsum</div> 
                        <div class="card-text">
                            
                            <div class="">
                            Informacije o svojim članovima ili igračima uvezi u naš roster i skini ogroman teret sa svojih leđa.
                            </div>
                        </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</section>

<section id="whatismentalhealth" class="position-relative">
    <div class="container pb-5">
        <div class="row">
            <div class="col-xl-12">
                <div class="mb-5">
                    <div class="title">
                        Znate li šta je to mentalno zdravlje?
                    </div>
                    <div class=" container-fluid">
                        <img class="position-absolute img-position" src="assets/images/line.png" alt="" >
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                Mentalno zdravlje nije samo odsustvo mentalnog poremećaja. Ono je definisano kao stanje blagostanja u kojem svaka osoba ostvaruje svoj potencijal, nosi se sa svakodnevnim stresom života, da može produktivno raditi i u mogućnosti je da doprinosi svojoj zajednici.
                Suština mentalnog zdravlja je jasna u definiciji mentalnog zdravlja SZO: "Zdravlje predstavlja kompletno fizičko, mentalno i socijalno blagostanje, a ne samo odsutvo bolesti i nemoć”. Mentalno zdravlje predstvlja integralni dio ove definicije.
                U svrhu javnog zdravlja i promocije zdravlja  korisno je primjeniti polje mentalnog zdravlja kao što je npr. prevencija infekcija ili kardio-vaskularnih bolesti.
            </div>
            <div class="fw-bold">Nema zdravlja bez mentalnog zdravlja!</div>
        </div>
    </div>
</section>

<section id="demo">
    <div class="container-fluid py-5">
        <div class="container d-flex justify-content-center align-items-center h-100">
          <div class="row d-flex justify-content-center align-items-center text-center">
                <h3 class="col-12 help-text">{{ 'Da li vam je potrebna pomoć?'}}</h3>
                <!-- <div class="py-2">
                  Unikatna / custom website samo za vas
                </div> -->
              
                <div class="pt-3">
                  <a  class="btn py-3">{{'KONTAKTIRAJTE NAS'}}</a>
                </div>
                <!-- <a asp-controller="Book" asp-action="Index" class="text-white">Browse collection -></a> -->
          </div>
        </div>
    </div>
  </section>

  <section id="news" class="position-relative">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 d-flex justify-content-between">
                <div class="d-flex flex-column">
                    <div class="title mb-5">
                        Novosti
                    </div>
                    <div class=" container-fluid">
                        <div class="line-holder">
                            <img class="position-absolute img-position" src="assets/images/line.png" alt="" >
                        </div>
                    </div>
                </div>
                
                <button class="btn btn-all-news text-center d-flex align-items-center px-auto">
                    PROČITAJ SVE NAŠE NOVOSTI
                </button>
            </div>
        </div>
        <div class="row mb-5 d-flex justify-content-between">
            <div class="col-xxl-4 col-xl-4 col-md-4 col-sm-12 my-4" >
            <!-- *ngFor="let blog of allBlogs | paginate : { 
              itemsPerPage: colSize,
              currentPage: page,
              totalItems: count
          };">-->
                <div class="card card-summer" style="width: 100%; height:100%;">
                  <div class="thumbnail-wrapper">
                      <img class="border-peach" src="assets/images/n1.jpg" alt="Card image cap">
                  </div>
                    
                    <div class="card-summer-body d-flex flex-column">
                      <div class="d-flex justify-content-between mt-4">
                        <div class="card-title">Lorem ipsum</div>
                        <!-- <div class="d-flex flex-column align-items-center justify-content-center">
                            <img src="assets/images/line.png" class="line">
                        </div> -->
                      </div>
                      <p class="card-text" style="font-family:'Poppins';" >
                        fdjfj jfhjsd hfhsfj sfhsjf hsdjfjks hfksd fkj skfj kl fklsdf lčsd f
                      </p>
                       
                    </div>
                    <div class="card-footer mt-4">
                      <button routerLink="" class="btn more-info-button text-center">PROČITAJ VIŠE</button>
                      
                    </div>
                  </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-md-4 col-sm-12 my-4" >
                    <div class="card card-summer" style="width: 100%; height:100%;">
                      <div class="thumbnail-wrapper">
                          <img class="border-peach" src="assets/images/n2.jpg" alt="Card image cap">
                      </div>
                        
                        <div class="card-summer-body d-flex flex-column">
                          <div class="d-flex justify-content-between mt-4">
                            <div class="card-title">Lorem ipsum</div>
                            <!-- <div class="d-flex flex-column align-items-center justify-content-center">
                                <img src="assets/images/line.png" class="line">
                            </div> -->
                          </div>
                          <p class="card-text" style="font-family:'Poppins';" >
                            fdjfj jfhjsd hfhsfj sfhsjf hsdjfjks hfksd fkj skfj kl fklsdf lčsd f
                          </p>
                           
                        </div>
                        <div class="card-footer mt-4">
                          <button routerLink="" class="btn more-info-button text-center">PROČITAJ VIŠE</button>
                          
                        </div>
                      </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-md-4 col-sm-12 my-4" >
                    <div class="card card-summer" style="width: 100%; height:100%;">
                      <div class="thumbnail-wrapper">
                          <img class="border-peach" src="assets/images/n3.jpg" alt="Card image cap">
                      </div>
                        
                        <div class="card-summer-body d-flex flex-column">
                          <div class="d-flex justify-content-between mt-4">
                            <div class="card-title">Lorem ipsum</div>
                            <!-- <div class="d-flex flex-column align-items-center justify-content-center">
                                <img src="assets/images/line.png" class="line">
                            </div> -->
                          </div>
                          <p class="card-text" style="font-family:'Poppins';" >
                            fdjfj jfhjsd hfhsfj sfhsjf hsdjfjks hfksd fkj skfj kl fklsdf lčsd f
                          </p>
                           
                        </div>
                        <div class="card-footer mt-4">
                          <button routerLink="" class="btn more-info-button text-center">PROČITAJ VIŠE</button>
                          
                        </div>
                      </div>
                </div>
        </div>
    </div>
  </section>

  <section id="contact" >
    <div class="container">
        <div class="row">
            <div class="col-xl-12 d-flex flex-column mb-5 justify-content-center align-items-center">
                <div>
                    <div class="title fw-bold t-center-mobile">
                        Kako doći do nas
                    </div>
                    <div class="">
                        <img class="img-position d-flex mx-auto" src="assets/images/line.png" alt="" >
                    </div>
                </div>
            </div>
           
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-8 text-center">
                <div>
                    Da li vam je potrebna pomoć, želite sa nekim popričati ili imate neku sugestiju, prijedlog za poboljšanje naših usluga slobodno
                </div>
                <button class="btn mt-3 more-info-button text-center">KONTAKTIRAJTE NAS</button>
            </div>
        </div>
        <div class="row d-flex justify-content-between my-5">
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-sm-3 card-mb-3">
                <div class="card card-contact">
                    <div class="card-body">
                        <div class="d-flex justify-content-center icon-contact my-3 icon-holder"><img src="assets/icons/location-contact.png"></div>
                        <!-- <div class="card-title text-center fw-bold pt-2">Lorem ipsum</div>  -->
                        <div class="card-text fw-bold">
                            Kulina bana T-8,
                            72250 Vitez,
                            BiH
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-sm-3 card-mb-3">
                <div class="card card-contact">
                    <div class="card-body">
                        <div class="d-flex justify-content-center  text-center icon-contact my-3 icon-holder"><img src="assets/icons/mail-contact.png"></div>
                        <!-- <div class="card-title text-center fw-bold pt-2">Lorem ipsum</div>  -->
                        <div class="card-text pt-2 fw-bold">
                            most.vitez@gmail.com
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div class="card card-contact">
                    <div class="card-body">
                        <!-- <div class="d-flex justify-content-center icon my-3"><img src="assets/icons/heart.png"></div>
                        <div class="card-title text-center fw-bold pt-2">Lorem ipsum</div>  -->
                        <div class="card-text pt-2">
                            <div>
                                Također možete pomoći našoj udruzi:
                            </div>
                            <br>
                            <div class="fw-bold">
                                3382502287390606
                                Unicredit Bank d.d.
                                Mostar, poslovnica Vitez
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
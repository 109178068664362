import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contantForm: any = {
    name: '',
    email: '',
    phone: '',
    message: ''
  };

  public post:any;

  public allProducts:Array<any>=[];
  
  public myGroup!: FormGroup;

  public most:any;

  constructor(){}

  ngOnInit(): void {

    // this.pageService.getAll(0,"","homepage",0, 1000, 'id', 'asc').subscribe(
    //   (response: any) => {
    //     this.post = response[0]; 
    //     console.log(response[0]);
    //   },
    //   err => {
    //     this.post = {};
    //   }
    // );

    // this.productService.getAll('', 0, 1000, 'id', 'asc').subscribe( 
    //   (response: any) => {
    //     console.log(response);
    //     this.allProducts = response.reverse(); 
    //   },
    //   err => {
    //     this.allProducts = []; 
    //   }
    // );

    this.most="most.vitez@gmail.com"

     this.myGroup = new FormGroup({
      _name: new FormControl ('', { validators: [Validators.required, Validators.minLength(5)] }), 
      _email: new FormControl ('', { validators: [Validators.required, Validators.email, 
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.minLength(10)] }), 
      _phone: new FormControl ('', { validators: [Validators.required, Validators.pattern('^06[0-9]{1}[0-9]{3}[0-9]{3}[0-9]?'), Validators.minLength(9), Validators.maxLength(10)], }), 
      _message: new FormControl ('', { validators: [Validators.required, Validators.minLength(5)] }), 
    });
  }

  get groupControls(): { [key: string]: AbstractControl }{
    return this.myGroup.controls;
  }
  
  submit(){
    console.log("submit form")
    // if(!this.myGroup.valid){
    //   Swal.fire({
    //     allowEscapeKey : false,
    //     allowOutsideClick: false,
    //     icon: 'error',
    //     title: 'Došlo je do greške!',
    //     text: 'Molimo ispunite formu pravilno.',
    //   });
    //   return;
    // }

    // Swal.fire({
    //   toast: false,
    //   title: 'Procesiranje..',
    //   text: 'Sačekajte momenat molim..',
    //   allowEscapeKey : false,
    //   allowOutsideClick: false,
    // });

    this.contantForm.name= this.myGroup.value._name;
    //this.contantForm.surname= this.myGroup.value._surname;
    this.contantForm.email= this.myGroup.value._email;
    this.contantForm.phone= this.myGroup.value._phone;
    this.contantForm.message= this.myGroup.value._message;

    if(this.contantForm.name !== '' && this.contantForm.surname !== '' && this.contantForm.email !== '' && this.contantForm.phone !== '' && this.contantForm.option !== '' && this.contantForm.message !== '') {
      var text = 'Ime: ' + this.contantForm.name;
      text += '<br>';
      text += 'Email: ' + this.contantForm.email;
      text += '<br>';
      text += 'Telefon: ' + this.contantForm.phone;
      text += '<br>';
      text += 'Poruka: ' + this.contantForm.message;
      text += '<br>';

    
      
      var objectAdmin = {
        to: 'lejlasp@hotmail.com',
        subject: 'Candly - Kontakt Forma',
        text: text
      };

      // This is for sending an e-mail to the user as well
      // var objectUser = {
      //   to: this.contantForm.email,
      //   subject: 'Amaregnum - Kontakt Forma',
      //   text: text
      // };

      // this.mailService.contact(objectAdmin).subscribe(
      //   (response: any) => {
      //     this.triggerSwal('success');
      //     console.log(response);
      //     this.myGroup.reset();
      //   },
      //   err => {
      //     this.triggerSwal('error');
      //     console.log(err);
      //   }
      // );

      // This is for executing the sending of an e-mail to the user as well
      // this.mailService.contact(objectUser).subscribe();
      //this.clearFields();

    }

  }

  triggerSwal(type?: string){
    
    var swalIcon: any = 'error';
    var swalText: any = 'Nepredvidiva greška!';
    var swalTitle: any = 'Greška';
    var contactUsAtEmail: any = 'lejlasp@hotmail.com';

  
      if(type == 'success') {
        swalIcon = 'success';
        swalText = 'E-mail uspješno poslan!<br> Uskoro ćemo Vas kontaktirati.';
        swalTitle = 'Poslano';
      } else if(type == 'error') {
        swalIcon = 'error';
        swalText = 'E-mail nije poslan!<br>Molimo pokušajte opet ili nas kontaktirajte direktno na ' + contactUsAtEmail;
        swalTitle = 'Greška';
      } else {
        swalIcon = 'error';
        swalText = 'Nepredviđena greška!<br>Molimo pokušajte opet ili nas kontaktirajte direktno na ' + contactUsAtEmail;
        swalTitle = 'Greška';
      }
   

    // Swal.fire({
    //   icon: swalIcon,
    //   html: swalText,
    //   title: swalTitle,
    //   confirmButtonColor: '#3085d6',
    //   confirmButtonText: 'OK',
    //   allowEscapeKey : false,
    //   allowOutsideClick: false
    // })
  }
}

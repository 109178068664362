import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent implements OnInit {

  public navOpen: boolean = false;
  public prevScrollpos = window.pageYOffset;
  @ViewChild('dropDown', {static: true}) topicDropdown!: ElementRef;

  public fragmentName: any;

  // public linkActiveOptions: IsActiveMatchOptions = {
  //   matrixParams: 'exact',
  //   queryParams: 'exact',
  //   paths: 'exact',
  //   fragment: 'exact',
  // };
  
  constructor(private router:Router, private route:ActivatedRoute) { }

  ngOnInit(): void {
  }

  onClickGoTo(path: any){
    this.router.navigate([path])
  .then(() => {
    window.location.reload(); 
  });
    
  }

  @HostListener('window:scroll', ['$event'])
  
  onWindowScroll() {

    let element = document.querySelector('.navbar') as HTMLElement;
    var currentScrollPos = window.pageYOffset;
    if (this.prevScrollpos > currentScrollPos) {
      element.style.top = "0%";
    } else {
      element.style.top = "-20%";
    }
    this.prevScrollpos = currentScrollPos;


    
  }

  openNav(){
    this.navOpen = !this.navOpen;

    let mobileNav = document.querySelector('.mobile-nav') as HTMLElement;

    if(this.navOpen == true){
      mobileNav.style.height = "auto";
      mobileNav.style.visibility = "visible";
    }else{
      mobileNav.style.height = "0px";
      mobileNav.style.visibility = "hidden";
    }

  }

  smoothDropOpen(){
    let element = document.getElementById('drop-menu');
    element?.classList.add('smooth-drop');

    //For mobile
    let element2 = document.getElementById('drop-menu-mb');
    element2?.classList.add('smooth-drop-mb');
  }

  smoothDropClose(){
    let element = document.getElementById('drop-menu');
    element?.classList.remove('smooth-drop');

    //For mobile
    let element2 = document.getElementById('drop-menu-mb');
    element?.classList.remove('smooth-drop-mb');
  }

  // @HostListener('document:click', ['$event'])
  // onGlobalClick(event: Event) {
  //   //close dropdown when clicking outside of it
  //   if (!this.topicDropdown.nativeElement.contains(event.target)) {
  //         this.smoothDropClose();
  //   }

  //   event.stopPropagation();
  // }



}

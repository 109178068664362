<section id="header-discover" class="responsive-bg d-flex justify-content-start">
    <!-- style.background-image="url('{{post?.headerImg?.url}}')"-->
        <div class="header-title text-white d-flex justify-content-start border-danger">
            {{'DOKUMENTI' }}
        </div> 
    </section>
    
    <section id="newslist">
        <div class="container">
            <div class="row mb-5 d-flex justify-content-between">
                <div class="col-xxl-4 col-xl-4 col-md-6 col-sm-12 my-4" *ngFor="let blog of tempArr | paginate : { 
                  itemsPerPage: colSize,
                  currentPage: page,
                  totalItems: count
              };">
                    <div class="card card-summer" style="width: 100%; height:100%;">
                      <div class="thumbnail-wrapper">
                          <img class="border-peach" src="assets/images/n1.jpg" alt="Card image cap">
                      </div>
                        
                        <div class="card-summer-body d-flex flex-column">
                          <div class="d-flex justify-content-between pt-3">
                            <div class="card-title text-center">Konvencija o pravima osoba s invalididtetom</div>
                            <!-- <div class="d-flex flex-column align-items-center justify-content-center">
                                <img src="assets/images/line.png" class="line">
                            </div> -->
                          </div>
                          <p class="card-text pt-2">
                            Konvencija o pravima osoba s invaliditetom potvrđuje ključna prava koja se moraju omogućiti osobama s invaliditetom. 
                          </p>
                           
                        </div>
                        <div class="card-footer pt-3">
                          <button routerLink="" class="btn more-info-button text-center">PROČITAJ VIŠE</button>
                          
                        </div>
                      </div>
                     
                </div>
    
                <div class="mt-5 d-flex justify-content-center"> 
                    <pagination-controls 
                    previousLabel=""
                      nextLabel="" 
                      (pageChange)="onColumnDataChange($event)">
                    </pagination-controls>
                  
                </div> 
            </div>
        </div>
    </section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  page = 1;
  count = 0;
  colSize = 6;
  colSizes = [3, 6, 9, 12];

  public post: any;
  constructor() { }

  ngOnInit(): void {
  }

  viewItem(id: string): string {
    return '/blog-detail/' + id;
  }

  onColumnDataChange(event: any){
    this.page = event; 
  }  

  onTableSizeChange(event: any): void {
    this.colSize = event.target.value;
    this.page = 1;
  }  

}

<section id="header-discover" class="responsive-bg d-flex justify-content-start">
<!-- style.background-image="url('{{post?.headerImg?.url}}')"-->
    <div class="header-title text-white d-flex justify-content-start border-danger">
        {{'MENTALNO ZDRAVLJE' }}
    </div> 
</section>

<section id="content1">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div>
                    Mentalno zdravlje nije samo odsustvo mentalnog poremećaja. Ono je definisano kao stanje blagostanja u kojem svaka osoba ostvaruje svoj potencijal, nosi se sa svakodnevnim stresom života, da može produktivno raditi i u mogućnosti je da doprinosi svojoj zajednici. U većini zemalja, naročito s niskim i srednjim dohotkom, usluge za mentalno zdravlje su u nedostatku resursa, kako ljudskih tako i finansijskih. 
                    <br><br>
                        Od raspoloživih sredstava zdravstvene zaštite, većina se trenutno troši na specijalizirano liječenje i njegu osoba s mentalnim bolestima,  a u  manjoj količini na integrirani sistem mentalnog zdravlja. Umjesto pružanja njege u velikim psihijatrijskim bolnicama, države bi trebale integrirati mentalno zdravlje u sklopu primarne zdravstvene zaštite i razviti usluge u općim bolnicama Promocija mentalnog zdravlja je moguća sa čak  manjim proporcijama finansija, krovni pojam koji obuhvata različite strategije s ciljem pozitivnog učinka na blagostanje mentalnog zdravlja u cjelini. Razvoj osobnih vještina i resursa, te poboljšanje u društveno-ekonomskom okruženju su dio strategija koje se koriste. 
                        <br><br>

                        Listen Promocija mentalnog zdravlja zahtijeva multisektoralni pristup, koji se odnosi na vladin sektor, različite nevladine organizacije ili udruženja građana. Fokus bi trebao biti na unapređenju mentalnog zdravlja kroz prevenciju određenih mentalnih poremećaja u periodu djetinstva s ciljem očuvanja mentalnog zdravlja u starosti. Mentalno zdravlje se definira kao stanje blagostanja u kojem svaka individual realizira svoje potencijale, sposobna je suočiti se sa normalnim stresnim životnim okolnostima, može raditi produktivno I plodnonosno I u mogućnosti je doprinjeti svojoj zajednici. Pozitivna dimenzija mentalnog zdravlja naglašena je u SZO definicijizdravlja kao što je sadržano u Ustavu: "Zdravlje je stanje potpunog fizičkog, psihičkog i društvenog blagostanja a ne samo odsutnost bolesti ili slabosti."

                </div>
            </div>
        </div>
    </div>
</section>

<section id="content2" class="my-4">
    <div class="container">
        <div class="row  d-flex py-2">
            <div class="col-xl-5">
                <div class="img-holder">
                    <img src="assets/images/mh.jpg" alt="">
                </div>
            </div>
            <div class="col-xl-7">
                <div class="content ">
                 Nema zdravlja bez mentalnog zdravlja <br>
                Suština mentalnog zdravlja je jasna u definiciji mentalnog zdravlja SZO: "Zdravlje predstavlja kompletno fizičko, mentalno i socijalno blagostanje, a ne samo odsutvo bolesti i nemoć”. Mentalno zdravlje predstvlja integralni dio ove definicije. U svrhu javnog zdravlja i promocije zdravlja  korisno je primjeniti polje mentalnog zdravlja kao što je npr. prevencija infekcija ili kardio-vaskularnih bolesti. 
                <br>
                Mentalno zdravlje predstavlja više od nepostojanja mentalnih poremećaja 
                Mentalno zdravlje se može koncipirati kao stanje dobrobiti, gdje svaka individua postaje svjesna njegovih ili njenih sposobnosti, može da se nosi sa normalnim stresom u životu, u stanju je da radi produktivno,te je sposoban-a da ostvari lični doprinos za svoju zajednicu. U ovom pozitivnom smislu, mentalno zdravlje predstavlja temelje za blagostanje i efikasno funkcionisanje kako za individuu, tako i za zajednicu. Ovaj ključni koncept mentalnog zdravlja je primjenljiv  za sve vrste različitih kultura. Promocija mentalnog zdravlja pokriva različite strategije koje za cilj imaju pozitivan uticaj na mentalno zdravlje. Kao i svaka druga promocija zdravlja, promocija mentalnog zdravlja uključuje određene akcije koje kreiraju životne uslove i okruženje koje je podržavajuće i koje dozvoljava ljudima da izgrade i sprovedu zdrave životne stilove. Ovo uključuje stepen akcija koje povećavaju prilike da veliki broj ljudi može iskusiti i uživati u boljem mentalnom zdravlju.
                </div>
            </div>
        </div>
    </div>
</section>

<section id="conten3">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div>
                    Mentalno zdravlje je određeno od strane socio-ekonomskih uticaja i uticaja okoline <br>
                    Mentalno zdravlje i poremećaji mentalnog zdravlja su određeni od strane višestrukih i interaktivnih faktora, kao što su: socijalni, psihološki, biološki i faktori generalnog zdravlja i bolesti. Najjasnija evidencija je povezana sa indikatorima moći, uključujući nizak stepen edukacije i prema nekim studijama slabo riješena stambena pitanja i slabe plate. Porast i konstantnost socio-ekonomskih prepreka za  pojedince predstavlja faktor rizika za mentalno zdravlje. Najveća osjetljivost  je utvrđena kod ljudi koji se nalaze u nepovoljnom položaju, te se ona objašnjava faktorima kao što su osjećaj nesigurnosti i beznadežnosti, brzih i iznenadnih promjene u društvu i rizika od mogućeg nasilja i fizičkih bolesti. Društvena klima koja poštuje i štiti osnovna ljudska, politička, socio-ekonomska i kulturna prava je fundamentalna za promociju mentalnog zdravlja. Ako u društvu ne postoji sigurnost i sloboda koja se garantuje kroz poštivanje ovih prava , jako je teško pričati o mentalnom zdravlja na višem nivou. 
                    Mentalno zdravlje je povezano sa ponašanjem <br>
                    Mentalni, socijalni  i zdravstveni problemi  mogu uticati na ponašanje i blagostanje. Zloupotreba supstanci, nasilje, kao i zloupotreba žena i djece u jednu ruku te zdravstveni problemi kao što su HIV/AIDS , depresija, anksioznost u drugu ruku su jako izraženi i mnogo je teže nositi se s njima u uslovima koji uključuju visoku stopu nezaposlenosti,mala primanja, limitiranu edukaciju, stres na poslu, rodnu i spolnu diskriminaciju, socijalnu isključenost, nezdrave životne stilovo i narušavanje ljudskih prava. Povećanje vrijednosti i značaj promocije mentalnog zdravlja Nacionalne politike o mentalnom zdravlju ne bi trebale sadržavati samo mentalne poremećaje, bitno je da se prepoznaju mnogo šira pitanja koja su bitna za promociju mentalnog zdravlja. Znači, ove politike bi trebale uključivati socio-ekonomske i okolinske faktore, koji su opisani u ovom tekstu, kao i značaj ponašanja.Ovo zahtijeva uključivanje promocije mentalnog zdravlja u politike i programe koji su povezani sa vladom i poslovnim sektorom, uključujući edukaciju, rad, pravdu, transport, okolinu,stan i  dobro, kao i zdravstveni sektor. Posebno je bitno da donosioci odluka u parlamentu na lokalnom i nacionalnom nivou, obuhvate pitanja mentalnog zdravlja na način koji do sada nije bio prisutan.
                </div>
            </div>
        </div>
    </div>
</section>

<section id="meantalhealth-bih" class="position-relative">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="mb-5">
                    <div class="title fw-bold">
                        Mentalno zdravlje u BiH
                    </div>
                    <div class=" container-fluid">
                        <img class="position-absolute img-position" src="assets/images/line.png" alt="" >
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <div>
                    Rezultat toga je da psihijatrijska služba nije više jedina organizaciona struktura koja brine o osobama koje pate od mentalnih poremećaja i poremećaja ponašanja. suštinska promjena konteksta pružanja usluga u mentalnom zdravlju podrazumijeva ograničeno korištenja psihijatrijskih bolničkih kreveta, otvaranje mreže centara za mentalno zdravlje (CMZ), razvoj drugih servisa u zajednici, multidisciplinarni pristup i timski rad, kao i međusektorsku saradnju.
                    Cilj ovih procesa je da se izgrade efektivne, djelotvorne i kvalitetne službe za zaštitu mentalnog zdravlja, koje slijede potrebe korisnika i dostupne su što većem broju ljudi, u kontekstu kompaktnog i integrisanog sistema pružanja usluga. Sistem pružanja usluga iz oblasti mentalnog zdravlja mora se zasnivati na praksi baziranoj na dokazima (evidence-based) i mora biti efikasan i prihvatljiv. Usluge iz oblasti mentalnog zdravlja u Bosni i Hercegovini pružaju se kroz mrežu od 50 centara za mentalno zdravlje (CMZ), i to 31 u Federaciji biH, 18 u Republici Srpskoj i jedan u distriktu Brčko. Svaki centar mentalnog zdravlja u FBiH ima na raspolaganju 10 psihijatrijskih kreveta koji su namijenjeni za akutnu hospitalizaciju i nalaze se na psihijatrijskim odjelima opštih bolnica u regijama kojima pripadaju. Usluge iz oblasti mentalnog zdravlja na sekundarnom i tercijarnom nivou pružaju se u univerzitetskim bolnicama u Sarajevu, Tuzli i Mostaru, i psihijatrijskim odjelima u opštim bolnicama u drugim većim gradovima u Federaciji. u republici srpskoj na sekundarnom i tercijarnom nivou usluge iz oblasti mentalnog zdravlja pružaju se u dvije univerzitetske bolnice: klinika za psihijatriju KC u Banjoj Luci i psihijatrijska klinika Sokolac (sadrži i forenzičku psihijatriju), te u zavodu za liječenje, rehabilitaciju i socijalnu zaštitu hroničnih duševnih bolesnika Jakeš i četiri psihijatrijska odjeljenja pri opštim bolnicama u Prijedoru, Gradiški, Doboju i Trebinju.
                </div>
            </div>
            <div class="col-xl-12 col-12 d-flex justify-content-center">
                <div class="map-holder py-5">
                     <img src="assets/images/map.jpg" alt="">
                </div>
               
            </div>
            <div class="col-xl-12">
                <div>
                    U sklopu sistema pružanja usluga mentalnog zdravlja u zajednici u BiH otvorene su tri zaštićene kuće, dvije kooperative, a formirano je i nekoliko udruženja korisnika usluga. U posljednjih godinu dana centri za socijalni rad u lokalnim zajednicama počeli su da otvaraju dnevne centre za osobe sa problemima mentalnog zdravlja, te dnevne centre za starije osobe, što upotpunjuje sistem službi i povećava usluge u mentalnom zdravlju. U centrima za mentalno zdravlje radi jedan ili više multidisciplinarnih timova, zavisno od veličine područja koje pokrivaju, odnosno pripadajuće populacije, i od raspoloživih kadrovskih resursa. U strukturi timova nalaze se neuropsihijatri/psihijatri, psiholozi, socijalni radnici, medicinski tehničari, defektolozi i logopedi, te okupacioni terapeuti. U većini mjesta u BiH gdje funkcionišu centri uočeni su pozitivni pomaci postignuti u rehabilitaciji i liječenju, smanjenju broja pacijenata i dužini hospitalizacije, uspostavljanju interdisciplinarne saradnje unutar zdravstvenog sistema, s jedne strane sa timovima opšte/ porodične medicine, a s druge sa bolničkim službama. Također je uspostavljena međusektorska saradnja na lokalnom nivou sa centrima za socijalni rad, školama, nevladinim udruženjima, lokalnim vlastima i dr.
                </div>
            </div>
        </div>
    </div>
</section>
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public screenWidth: any;  
  public screenHeight: any;  
  constructor() { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;  
    this.screenHeight = window.innerHeight;  

    console.log(this.screenHeight);
    console.log(this.screenWidth);
  }

  @HostListener('window:resize', ['$event'])  
  onResize(event: any) {  
    this.screenWidth = window.innerWidth;  
    this.screenHeight = window.innerHeight;  
  }  
}

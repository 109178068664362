<section id="header-discover" class="responsive-bg d-flex justify-content-start">
<!-- style.background-image="url('{{post?.headerImg?.url}}')"-->
  
</section>
    

<section id="content">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="title text-center mb-5">
                    Lorem ipsum dolor sit amet
                </div>
            </div>
            <div class="col-xl-12">
                Iako su UDLJP i drugi ugovori namijenjeni zaštiti prava svih ljudi, realnost je da društvo osobama s invaliditetom i dalje uskraćuje ta prava.
                Zbog toga je 2006. usvojena Konvencija o pravima osoba sa invaliditetom. Ova Konvencija stvara pravno obavezujuće obaveze za zemlje da promovišu i štite sva ljudska prava osoba sa invaliditetom na ravnopravnoj osnovi sa drugima.
                Šta mislite  zašto je postojala potreba za stvaranjem novog ugovora/konvencije o ljudskim pravima - Konvencije o pravima osoba s invaliditetom?
                Konvencija o pravima osoba s invaliditetom potvrđuje ključna prava koja se moraju omogućiti osobama s invaliditetom. 
                Konvencija je veliki korak ka promjeni percepcije invaliditeta u društvu i osiguravanju da zemlje prepoznaju da se osobama s invaliditetom mora pružiti prilika da žive punim potencijalom, na ravnopravnoj osnovi sa svim drugim ljudima.
                Konvencija nije imala za cilj stvaranje novih prava - već pokazati kako se postojeća prava primjenjuju na osobe s invaliditetom.
                Ljudi sa psihosocijalnim smetnjama i psihičkom bolesti se u svakodnevnom životu  suočavaju s mnogim preprekama i diskriminacijom koje ih sprečavaju u učešću u društvu i stvaraju invaliditet. Zato su zaštićeni Konvencijom, kao i ostale osobe s invaliditetom.
                Tradicionalna njega i liječenje mentalnih poremećaja, zasnovani na institucionalizaciji i prisili, ne mogu biti prihvatljivi prema Konvenciji.
                Konvencija je dosljedna pristupu temeljenom na oporavku.
                Pristup  oporavka poštuje ljudska prava i autonomiju i podržava društvenu povezanost, nadu, osnaživanje i pozitivno preuzimanje rizika. 
                Uzima u obzir sve društvene odrednice mentalnog zdravlja, kao što su odnosi, obrazovanje, zaposlenje - tj. svi elementi života osobe koji imaju smisao i koji mogu imati pozitivan ili negativan utjecaj na njeno mentalno zdravlje.
                Konvencija je pravno obavezujući instrument.
                To znači da su zemlje ratifikacijom ove Konvencije dužne poduzeti čitav niz mjera kako bi osigurale da osobe s invaliditetom imaju ista prava kao i svi, da se prema njima postupa pošteno i jednako i da se ne diskriminiraju.
                Pročitajte više na našoj prezentaciji, možete je skinuti sa: https://drive.google.com

            </div>
        </div>
    </div>
</section>

<section id="content2" class="my-4">
    <div class="container">
        <div class="row  d-flex ">
            <div class="col-xl-5">
                <div class="img-holder">
                    <img src="assets/images/news1.png" alt="">
                </div>
            </div>
            <div class="col-xl-7">
                <div class="content ">
                 Nema zdravlja bez mentalnog zdravlja <br>
                Suština mentalnog zdravlja je jasna u definiciji mentalnog zdravlja SZO: "Zdravlje predstavlja kompletno fizičko, mentalno i socijalno blagostanje, a ne samo odsutvo bolesti i nemoć”. Mentalno zdravlje predstvlja integralni dio ove definicije. U svrhu javnog zdravlja i promocije zdravlja  korisno je primjeniti polje mentalnog zdravlja kao što je npr. prevencija infekcija ili kardio-vaskularnih bolesti. 
                <br>
                Mentalno zdravlje predstavlja više od nepostojanja mentalnih poremećaja 
                Mentalno zdravlje se može koncipirati kao stanje dobrobiti, gdje svaka individua postaje svjesna njegovih ili njenih sposobnosti, može da se nosi sa normalnim stresom u životu, u stanju je da radi produktivno,te je sposoban-a da ostvari lični doprinos za svoju zajednicu. U ovom pozitivnom smislu, mentalno zdravlje predstavlja temelje za blagostanje i efikasno funkcionisanje kako za individuu, tako i za zajednicu. Ovaj ključni koncept mentalnog zdravlja je primjenljiv  za sve vrste različitih kultura. Promocija mentalnog zdravlja pokriva različite strategije koje za cilj imaju pozitivan uticaj na mentalno zdravlje. Kao i svaka druga promocija zdravlja, promocija mentalnog zdravlja uključuje određene akcije koje kreiraju životne uslove i okruženje koje je podržavajuće i koje dozvoljava ljudima da izgrade i sprovedu zdrave životne stilove. Ovo uključuje stepen akcija koje povećavaju prilike da veliki broj ljudi može iskusiti i uživati u boljem mentalnom zdravlju.
                </div>
            </div>
        </div>
    </div>
</section>

<section id="conten3">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div>
                    Mentalno zdravlje je određeno od strane socio-ekonomskih uticaja i uticaja okoline <br>
                    Mentalno zdravlje i poremećaji mentalnog zdravlja su određeni od strane višestrukih i interaktivnih faktora, kao što su: socijalni, psihološki, biološki i faktori generalnog zdravlja i bolesti. Najjasnija evidencija je povezana sa indikatorima moći, uključujući nizak stepen edukacije i prema nekim studijama slabo riješena stambena pitanja i slabe plate. Porast i konstantnost socio-ekonomskih prepreka za  pojedince predstavlja faktor rizika za mentalno zdravlje. Najveća osjetljivost  je utvrđena kod ljudi koji se nalaze u nepovoljnom položaju, te se ona objašnjava faktorima kao što su osjećaj nesigurnosti i beznadežnosti, brzih i iznenadnih promjene u društvu i rizika od mogućeg nasilja i fizičkih bolesti. Društvena klima koja poštuje i štiti osnovna ljudska, politička, socio-ekonomska i kulturna prava je fundamentalna za promociju mentalnog zdravlja. Ako u društvu ne postoji sigurnost i sloboda koja se garantuje kroz poštivanje ovih prava , jako je teško pričati o mentalnom zdravlja na višem nivou. 
                    Mentalno zdravlje je povezano sa ponašanjem <br>
                    Mentalni, socijalni  i zdravstveni problemi  mogu uticati na ponašanje i blagostanje. Zloupotreba supstanci, nasilje, kao i zloupotreba žena i djece u jednu ruku te zdravstveni problemi kao što su HIV/AIDS , depresija, anksioznost u drugu ruku su jako izraženi i mnogo je teže nositi se s njima u uslovima koji uključuju visoku stopu nezaposlenosti,mala primanja, limitiranu edukaciju, stres na poslu, rodnu i spolnu diskriminaciju, socijalnu isključenost, nezdrave životne stilovo i narušavanje ljudskih prava. Povećanje vrijednosti i značaj promocije mentalnog zdravlja Nacionalne politike o mentalnom zdravlju ne bi trebale sadržavati samo mentalne poremećaje, bitno je da se prepoznaju mnogo šira pitanja koja su bitna za promociju mentalnog zdravlja. Znači, ove politike bi trebale uključivati socio-ekonomske i okolinske faktore, koji su opisani u ovom tekstu, kao i značaj ponašanja.Ovo zahtijeva uključivanje promocije mentalnog zdravlja u politike i programe koji su povezani sa vladom i poslovnim sektorom, uključujući edukaciju, rad, pravdu, transport, okolinu,stan i  dobro, kao i zdravstveni sektor. Posebno je bitno da donosioci odluka u parlamentu na lokalnom i nacionalnom nivou, obuhvate pitanja mentalnog zdravlja na način koji do sada nije bio prisutan.
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section id="gallery">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="title text-center mb-5">
                    Galerija
                </div>
            </div>
        </div>
    </div>
</section> -->

<section id="gallery" class="home-sec my-5 mx-0 p-0 ">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-12 d-flex flex-column mb-5 justify-content-center align-items-center">
            <div>
                <div class="title fw-bold text-center t-center-mobile">
                    Galerija
                </div>
                <div class="position-absolute img-position">
                    <img class="d-flex" src="assets/images/line.png" alt="" >
                </div>
            </div>
        </div>
       
    </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mobile-arrows">
            <a class="mb-3 mx-3 margin-between-mobile" role="button" data-slide="prev" onClick="$('#carouselExampleIndicators2').carousel('prev')">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="mb-3 mx-3 margin-between-mobile" role="button" data-slide="next" onClick="$('#carouselExampleIndicators2').carousel('next')">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
        </div>
        <div class="row">
          <div class="col-xxl-12 d-flex justify-content-center">
              <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner">
                      <div class="carousel-item active">
                          <div class="row d-flex justify-content-center">
                              <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-3 p-0">
                                <div class="gallery-card-1 px-3 py-3">
                                  <img src="assets/images/n1.jpg" class="gallery-card-img" alt="...">
                                </div>
                              </div>
                              <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-3 p-0">
                                <div class="gallery-card-2 px-3 py-3">
                                  <img src="assets/images/n1.jpg"  class="gallery-card-img" alt="...">
                                </div>
                              </div>
                              <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-3 p-0">
                                <div class="gallery-card-3 px-3 py-3">
                                  <img src="assets/images/n1.jpg"  class="gallery-card-img" alt="...">   
                                </div>
                              </div>
                              <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-3 p-0">
                                <div class="gallery-card-4 px-3 py-3">
                                  <img src="assets/images/n1.jpg"  class="gallery-card-img" alt="...">   
                                </div>
                              </div>
                          </div>
                      </div>
                      <div class="carousel-item">
                          <div class="row d-flex justify-content-center">
  
                            <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-3 p-0">
                              <div class="gallery-card-4 px-3 py-3">
                                <img src="assets/images/n1.jpg"  class="gallery-card-img" alt="...">
                              </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-3 p-0">
                              <div class="gallery-card-3 px-3 py-3">
                                <img src="assets/images/n1.jpg" class="gallery-card-img" alt="...">
                              </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-3 p-0">
                              <div class="gallery-card-2 px-3 py-3">
                                <img src="assets/images/n1.jpg"  class="gallery-card-img" alt="...">
                              </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-3 p-0">
                              <div class="gallery-card-1 px-3 py-3">
                                <img src="assets/images/n1.jpg"  class="gallery-card-img" alt="...">
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
                  <a class="carousel-control-prev desktop-arrows"  role="button" data-slide="prev"  onClick="$('#carouselExampleIndicators2').carousel('prev')">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next desktop-arrows"  role="button" data-slide="next" onClick="$('#carouselExampleIndicators2').carousel('next')">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
              </div>
          </div>
      </div>
  </div>
  </section>
  
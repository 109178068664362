import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  public allBlogs: Array<any> = [];

  page = 1;
  count = 0;
  colSize = 3;
  colSizes = [3, 6, 9, 12];

  public post: any;

  tempArr: string[] = ['Apple', 'Orange', 'Banana', 'kjfkds', 'jhjfdhdsjf', 'jhfjsh', 'kjjfdsf'];
 

  constructor() { }

  ngOnInit(): void {
    var _this = this;

    

    // this.pageService.getAll(0,"","blogovi",0, 1000, 'id', 'asc').subscribe(
    //   (response: any) => {
    //     this.post = response[0]; 
    //     console.log(response[0]);
    //   },
    //   err => {
    //     this.post = {};
    //   }
    // );

    // this.postService.getAll('', 0, "", "Active", 0, 999, 'id', 'asc').subscribe( 
    //   (response: any) => {
    //     console.log(response);
    //     _this.allBlogs = response.reverse(); 
    //   },
    //   err => {
    //     _this.allBlogs = []; 
    //   }
    // );
  }

  viewItem(id: string): string {
    return '/blog-detail/' + id;
  }

  onColumnDataChange(event: any){
    this.page = event; 
  }  

  onTableSizeChange(event: any): void {
    this.colSize = event.target.value;
    this.page = 1;
  }  


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mental-health',
  templateUrl: './mental-health.component.html',
  styleUrls: ['./mental-health.component.scss']
})
export class MentalHealthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

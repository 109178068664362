import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicFooterComponent } from './components/public-footer/public-footer.component';
import { PublicHeaderComponent } from './components/public-header/public-header.component';
import { HomeComponent } from './pages/public/home/home.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AboutComponent } from './pages/public/about/about.component';
import { NewsComponent } from './pages/public/news/news.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NewsDetailComponent } from './pages/public/news-detail/news-detail.component';
import { MentalHealthComponent } from './pages/public/mental-health/mental-health.component';
import { ProjectsComponent } from './pages/public/projects/projects.component';
import { YourRightsComponent } from './pages/public/your-rights/your-rights.component';
import { DocumentsComponent } from './pages/public/documents/documents.component';
import { DocumentDetailComponent } from './pages/public/document-detail/document-detail.component';
import { ContactComponent } from './pages/public/contact/contact.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProjectDetailsComponent } from './pages/public/project-details/project-details.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    PublicFooterComponent,
    PublicHeaderComponent,
    HomeComponent,
    PublicLayoutComponent,
    LoginLayoutComponent,
    AdminLayoutComponent,
    AboutComponent,
    NewsComponent,
    NewsDetailComponent,
    MentalHealthComponent,
    ProjectsComponent,
    YourRightsComponent,
    DocumentsComponent,
    DocumentDetailComponent,
    ContactComponent,
    ProjectDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    //HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
   // AngularEditorModule ,
    NgxPaginationModule
  ],
providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

<section id="header-discover" class="responsive-bg d-flex justify-content-start">
    <div class="header-title text-white d-flex justify-content-start border-danger">
        {{'Dokument' }}
    </div> 
</section>

<section id="content">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="title text-center mb-5">
                    Konvencija o pravima osoba sa invaliditetom
                </div>
            </div>
            <div class="col-xl-12">
                Iako su UDLJP i drugi ugovori namijenjeni zaštiti prava svih ljudi, realnost je da društvo osobama s invaliditetom i dalje uskraćuje ta prava.
Zbog toga je 2006. usvojena Konvencija o pravima osoba sa invaliditetom. Ova Konvencija stvara pravno obavezujuće obaveze za zemlje da promovišu i štite sva ljudska prava osoba sa invaliditetom na ravnopravnoj osnovi sa drugima.
Šta mislite  zašto je postojala potreba za stvaranjem novog ugovora/konvencije o ljudskim pravima - Konvencije o pravima osoba s invaliditetom?
Konvencija o pravima osoba s invaliditetom potvrđuje ključna prava koja se moraju omogućiti osobama s invaliditetom. 
Konvencija je veliki korak ka promjeni percepcije invaliditeta u društvu i osiguravanju da zemlje prepoznaju da se osobama s invaliditetom mora pružiti prilika da žive punim potencijalom, na ravnopravnoj osnovi sa svim drugim ljudima.
Konvencija nije imala za cilj stvaranje novih prava - već pokazati kako se postojeća prava primjenjuju na osobe s invaliditetom.
Ljudi sa psihosocijalnim smetnjama i psihičkom bolesti se u svakodnevnom životu  suočavaju s mnogim preprekama i diskriminacijom koje ih sprečavaju u učešću u društvu i stvaraju invaliditet. Zato su zaštićeni Konvencijom, kao i ostale osobe s invaliditetom.
Tradicionalna njega i liječenje mentalnih poremećaja, zasnovani na institucionalizaciji i prisili, ne mogu biti prihvatljivi prema Konvenciji.
Konvencija je dosljedna pristupu temeljenom na oporavku.
Pristup  oporavka poštuje ljudska prava i autonomiju i podržava društvenu povezanost, nadu, osnaživanje i pozitivno preuzimanje rizika. 
Uzima u obzir sve društvene odrednice mentalnog zdravlja, kao što su odnosi, obrazovanje, zaposlenje - tj. svi elementi života osobe koji imaju smisao i koji mogu imati pozitivan ili negativan utjecaj na njeno mentalno zdravlje.
Konvencija je pravno obavezujući instrument.
To znači da su zemlje ratifikacijom ove Konvencije dužne poduzeti čitav niz mjera kako bi osigurale da osobe s invaliditetom imaju ista prava kao i svi, da se prema njima postupa pošteno i jednako i da se ne diskriminiraju.
Pročitajte više na našoj prezentaciji, možete je skinuti sa: https://drive.google.com

            </div>
        </div>
    </div>
</section>